//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import "swiper/dist/css/swiper.css";
import $ from "jquery";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { getDetailApi, addDownLog } from "../api";
import { mapState } from "vuex";
import Login from "../components/Login";

export default {
  name: "Detail",
  components: {
    Swiper,
    SwiperSlide,
    Login,
  },
  data() {
    return {
      no_content: false,
      no_num: 3,
      isLoading: true,
      detail: "",
      id: this.$route.params.id,
      swiperOptions: {
        slidesPerView: 7,
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionsTwo: {
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-next2",
          prevEl: ".swiper-button-prev2",
        },
      },
      dialogVisible: false,
      isShowDownLoad: false,
      isShowRight: false,
      video: {},
      role: 1,
      yuyan_id: 1,
      languages: "",
      JXvideo_name: "", //接口要的作品名字
      video_name_list: [], //接口要的名字数组
      languagesID: "",
      languagesII: "",
      languagesList: [],
      xzlanguagesList: [],
      video_num: 21,
      series: [],
      allSetNnumber: [],
      selectedNumber: 0,
      isCheckAll: false,
      isShowLogin: false,
      dow_key: "",
      lang: localStorage.getItem("Lang"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.isLogin,
    }),
  },
  async created() {
    if (JSON.parse(sessionStorage.getItem("user"))) {
      this.dow_key = JSON.parse(sessionStorage.getItem("user")).dow_key;
    }
    this.id = this.$route.params.id;
    await this.getDetail();
    await this.getlanguages();

    for (let i = 0; i < this.xzlanguagesList.length; i++) {
      this.series.push({
        id: i,
        title: this.xzlanguagesList[i].name,
        length: this.xzlanguagesList[i].file_length,
        isSelected: false,
      });
    }
  },
  async activated() {
    this.id = this.$route.params.id;
    await this.getDetail();
    for (let i = 0; i < this.xzlanguagesList.length; i++) {
      this.series.push({
        id: i,
        title: this.xzlanguagesList[i].name,
        length: this.xzlanguagesList[i].file_length,
        isSelected: false,
      });
    }
  },
  watch: {
    languagesList() {
      this.changeLG();
    },
  },
  methods: {
    toApply() {
      this.$router.push({ name: "apply" });
    },
    getlanguages() {
      let video_name = this.detail.so_keword;
      let that = this;
      let url = encodeURI(
        `https://mediadown.chinapavilion.com.cn/get_video_list?video_name=${video_name}`
      );
      $.ajax({
        url: url, //url路径
        type: "GET", //GET
        data: {
          //参数
        },
        timeout: 5000, //超时时间
        dataType: "json", //返回的数据格式：
        beforeSend: function (xhr) {},
        success: function (data, src, code) {
          if (code.responseJSON.code == 1) {
            that.languagesList = data.videos;
            that.languagesII = data.videos[0].id;
            that.languages = data.videos[0].lang;
          } else {
            // that.$message.error(code.responseJSON.mas);
          }
        },
        error: function (xhr) {
          // that.$message.error(xhr.data.mas);
        },
        complete: function () {},
      });
      // fetch(
      //   `https://mediadown.chinapavilion.com.cn/get_video_list?video_name=${video_name}`
      // )
      //   .then((response) => response.json())
      //   .then((result) => {
      //     this.languagesList = result.videos;
      //     this.languagesII = result.videos[0].id;
      //     this.languages = result.videos[0].lang;
      //   })
      //   .catch((error) => {
      //     this.$message.error(error.data.mas);
      //   });
    },
    changeLG() {
      this.selectedNumber = 0;
      this.allSetNnumber = [];
      this.video_name_list = [];
      let that = this;
      let id = this.languagesList[0].id;
      for (let i = 0; i < this.languagesList.length; i++) {
        if (this.languagesList[i].lang == this.languages) {
          id = this.languagesList[i].id;
        }
      }
      let url = encodeURI(
        `https://mediadown.chinapavilion.com.cn/get_video_info?id=${id}`
      );
      $.ajax({
        url: url, //url路径
        type: "GET", //GET
        async: false, //或false,是否异步
        data: {
          //参数
        },
        timeout: 5000, //超时时间
        dataType: "json", //返回的数据格式：
        beforeSend: function (xhr) {},
        success: function (data, textStatus, jqXHR) {
          that.xzlanguagesList = data.data.video_list;
          that.series = [];
          that.JXvideo_name = data.data.video_name;
          for (let i = 0; i < that.xzlanguagesList.length; i++) {
            that.series.push({
              id: i,
              title: that.xzlanguagesList[i].name,
              length: that.xzlanguagesList[i].file_length,
              isSelected: false,
            });
          }
        },
        error: function (xhr, textStatus) {
          // that.$message.error(xhr.data.info);
        },
        complete: function () {},
      });
      // fetch(`https://mediadown.chinapavilion.com.cn/get_video_info?id=${id}`)
      //   .then((response) => response.json())
      //   .then((result) => {
      //     // this.video_num = parseInt(result.data.video_nmber);
      //     this.xzlanguagesList = result.data.video_list;
      //     this.series = [];
      //     this.JXvideo_name = result.data.video_name;
      //     for (let i = 0; i < this.xzlanguagesList.length; i++) {
      //       this.series.push({
      //         id: i,
      //         title: this.xzlanguagesList[i].name,
      //         length: this.xzlanguagesList[i].file_length,
      //         isSelected: false,
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.$message.error(error.data.info);
      //   });
    },
    async getDetail() {
      this.isLoading = true;
      if (this.lang == "en") {
        this.yuyan_id = 2;
      } else {
        this.yuyan_id = 1;
      }
      try {
        const res = await getDetailApi({
          id: this.id,
          yuyan_id: this.yuyan_id,
        });
        this.detail = res.data;

        this.isLoading = false;
        // console.log(res.data);
      } catch (e) {
        this.isLoading = false;
        console.log(e);
        if (e.code == 0) {
          this.noDetail();
        }
      }
    },
    noDetail() {
      // console.log("1111");
      this.no_content = true;
      let outtime = setInterval(() => {
        //1秒后跳转
        this.no_num--;
        if (this.no_num == 0) {
          clearInterval(outtime);
          this.$router.push("/");
        }
      }, 1000);
    },
    toIndex() {
      this.$router.push("/");
    },
    isDownload() {
      if (!this.isLogin) {
        this.isShowLogin = true;
        return;
      }
      if (this.user.role == 1 && this.user.meizi_down == 1) {
        this.isShowDownLoad = true;
      } else {
        this.isShowRight = true;
      }
      // this.$message({
      //   offset: 200,
      //   message: this.$t('tips.building')
      // })
    },
    play(data) {
      this.video = data;
      console.log(data);
      this.dialogVisible = true;
    },
    close() {
      console.log(this.$refs);
      this.$refs.myVideo.pause();
      this.dialogVisible = false;
    },
    choose(index) {
      if (!this.series[index].isSelected) {
        this.allSetNnumber.push(
          `${this.xzlanguagesList[index].down_url}&dow_key=${
            JSON.parse(sessionStorage.getItem("user")).dow_key
          }`
        );
        this.video_name_list.push(this.xzlanguagesList[index].name);
      } else {
        for (let i = 0; i < this.allSetNnumber.length; i++) {
          if (
            this.allSetNnumber[i] ==
            `${this.xzlanguagesList[index].down_url}&dow_key=${
              JSON.parse(sessionStorage.getItem("user")).dow_key
            }`
          ) {
            this.allSetNnumber.splice(i, 1);
          }
          if (this.video_name_list[i] == this.xzlanguagesList[index].name) {
            this.video_name_list.splice(i, 1);
          }
        }
      }
      this.series[index].isSelected = !this.series[index].isSelected;
      this.series[index].isSelected
        ? this.selectedNumber++
        : this.selectedNumber--;
    },
    chooseAll(val) {
      if (val) {
        for (let i = 0; i < this.series.length; i++) {
          this.allSetNnumber.push(this.xzlanguagesList[i].down_url);
        }

        this.series.forEach((item) => (item.isSelected = true));
        this.selectedNumber = this.series.length;
      } else {
        this.series.forEach((item) => (item.isSelected = false));
        this.selectedNumber = 0;
        this.allSetNnumber = [];
      }
    },
    submitDownLoad() {
      let time = 500;
      let uid = JSON.parse(sessionStorage.getItem("user")).uid;
      if (this.xzlanguagesList == [] && this.allSetNnumber.length == 0) {
        this.$message("当前下载影视为空不能下载");
      } else if (this.allSetNnumber.length == 0) {
        this.$message("当前下载影视为空不能下载");
      } else {
        if (this.allSetNnumber.length > 1) {
          for (let i = 0; i < this.allSetNnumber.length; i++) {
            setTimeout(() => {
              let h5URL = this.allSetNnumber[i];
              window.location.href = h5URL;
            }, time * i);
          }
        } else if ((this.allSetNnumber.length = 1)) {
          let h5URL = this.allSetNnumber[0];
          window.location.href = h5URL;
        }
        try {
          const res = addDownLog({
            uid: uid,
            video_name: this.JXvideo_name,
            names: this.video_name_list,
          });
        } catch (e) {
          console(e);
        }
      }
    },
  },
};
